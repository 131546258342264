const Noodl = require('@noodl/noodl-sdk');

import { useRef, useEffect } from 'react';

import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css'; //import the css using webpack

//a very simple react component that tells the caller when it's <div> is mounted and unmounted
//defaults to 100% width and height, so wrap in a Group in Noodl to get control over margins, dimensions, etc
function DivComponent(props) {
  const ref = useRef(null);

  useEffect(() => {
    props.onDidMount(ref.current);
    return () => props.onWillUnmount(ref.current);
  });

  const { style, ...otherProps } = props;

  return (
    <div {...otherProps} style={{...{width: '100%', height: '100%'}, ...style}}>
      <div style={{width: '100%', height: '100%'}} ref={ref} />
      <div style={{width: '100%', height: '100%'}}>{props.children}</div>
    </div>
  );
}

const MapboxNode = Noodl.defineReactNode({
  name: 'Mapbox Map',
  category: 'Mapbox',
  docs: 'https://docs.noodl.net/modules/mapbox/mapbox-map',
  getReactComponent() {
    return DivComponent;
  },
  initialize() {
    //wait for the div to mount before we create the map instance
    this.props.onDidMount = domElement => {
      this.initializeMap(domElement);
    };

    this.props.onWillUnmount = () => {
      this.map.remove();
    }

    this._markerAddedCB = this._markerAdded.bind(this)
    this._markerRemovedCB = this._markerRemoved.bind(this)

    this.markerImageProps = {}
  },
  methods: {
    getCenter() {
      return [(this.inputs.longitude === undefined) ? 0 : this.inputs.longitude, (this.inputs.latitute === undefined) ? 0 : this.inputs.latitute]
    },
    initializeMap(domElement) {

      const accessToken = Noodl.getProjectSettings().mapboxAccessToken;

      if (!accessToken) {
        //present a warning in the editor
        this.sendWarning('access-token-missing', 'No access token. Please specify one in project settings and reload');
      } else {
        //clear any previous warnings, if any
        this.clearWarnings();
      }

      mapboxgl.accessToken = accessToken;

      const map = new mapboxgl.Map({
        container: domElement,
        style: this.inputs.mapboxStyle || 'mapbox://styles/mapbox/streets-v11',
        center: this.getCenter(),
        zoom: (this.inputs.zoom === undefined) ? 0 : this.inputs.zoom,
        interactive: (this.inputs.interactive === undefined) ? true : this.inputs.interactive
      });

      new ResizeObserver(() => {
        map.resize()
      }).observe(domElement)

      this.map = map;
      this.setOutputs({ map });

      map.on('move', () => {
        this.setOutputs({
          longitude: map.getCenter().lng,
          latitute: map.getCenter().lat,
          zoom: map.getZoom()
        })
        this.sendSignalOnOutput('mapMoved')
      });

      this.geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      });

      map.addControl(this.geolocate);

      map.on('load', () => {
        // Markers
        if (this.markersArray !== undefined && this._markersArray === undefined) {
          this._createMarkers()
        }

        this.sendSignalOnOutput("mapLoaded");
      })

      map.on('click', (e) => {
        this.setOutputs({
          clickedLon: e.lngLat.lng,
          clickedLat: e.lngLat.lat,
        })
        this.sendSignalOnOutput("mapClicked");
      })
    },
    _markerAdded(ev) {
      if (this.map === undefined) return

      if (this._markersArray !== undefined) {
        const marker = this._addMarker(ev.item)
        this._markersArray.splice(ev.index, 0, marker)
      }
    },
    _markerRemoved(ev) {
      if (this.map === undefined) return

      if (this._markersArray !== undefined) {
        this._markersArray[ev.index].remove()
        this._markersArray.splice(ev.index, 1)
      }
    },
    _addMarker(m) {
      let element
      let anchor
      if (this.inputs.markerType === 'image') {
        element = document.createElement("img")

        const props = this.markerImageProps
        element.setAttribute('src', props.Source)

        const width = (props.Width !== undefined) ? (props.Width.value + props.Width.unit) : '20px'
        const height = (props.Width !== undefined) ? (props.Width.value + props.Width.unit) : '20px'
        element.setAttribute('style', `width:${width}; height:${height}`)

        anchor = props.Anchor !== undefined ? props.Anchor : 'center'
      }

      const marker = new mapboxgl.Marker({
          color: m.get('Color') || "black",
          element,
          anchor
        }).setLngLat([m.get('Lon'), m.get('Lat')])
        .addTo(this.map)

      marker.getElement().addEventListener('click', (ev) => {
        this.setOutputs({
          clickedMarkerId: m.getId()
        })
        this.sendSignalOnOutput('markerClicked')
        ev.stopPropagation()
      })
      marker.getElement().setAttribute('data-ndl-marker-id', m.getId())

      return marker
    },
    _createMarkers() {
      this._markersArray = []
      this.markersArray.each((m) => {
        const marker = this._addMarker(m)
        this._markersArray.push(marker)
      })
    },
    registerInputIfNeeded(name) {
      if (this.hasInput(name)) {
        return;
      }

      if (name.startsWith('markerImage')) return this.registerInput(name, {
        set: this.setMarkerImageProps.bind(this, name.substring('markerImage'.length))
      })
    },
    setMarkerImageProps(name, value) {
      this.markerImageProps[name] = value
    }
  },
  inputs: {
    //options
    mapboxStyle: {
      displayName: 'Style',
      group: 'Options',
      type: 'string',
      default: 'mapbox://styles/mapbox/streets-v11'
    },
    interactive: {
      displayName: 'Interactive',
      type: 'boolean',
      default: true
    },

    //coordinates and zoom
    longitude: {
      displayName: 'Longitude',
      type: 'number',
      group: 'Coordinates',
      default: 0
    },
    latitute: {
      displayName: 'Latitude',
      type: 'number',
      group: 'Coordinates',
      default: 0
    },
    zoom: {
      displayName: 'Zoom',
      type: 'number',
      group: 'Coordinates',
      default: 0
    },

    // Markers
    markers: {
      displayName: 'Markers',
      type: 'array',
      group: 'Markers'
    },
    markerType: {
      displayName: 'Type',
      type: {
        name: 'enum',
        enums: [{
          label: 'Default',
          value: 'default'
        }, {
          label: 'Image',
          value: 'image'
        }]
      },
      group: 'Markers',
      default: 'default'
    },
  },
  changed: {
    zoom(level) {
      if (this.map == undefined) return
      this.map.setZoom(level)
    },
    longitude() {
      if (this.map == undefined) return
      this.map.setCenter(this.getCenter())
    },
    latitute() {
      if (this.map == undefined) return
      this.map.setCenter(this.getCenter())
    },
    markers(markersArray) {
      if (this.markersArray !== undefined) {
        // remove all markers from old array
        this._markersArray.forEach((m) => m.remove())
        this._markersArray = undefined
        this.markersArray.off('add', this._markerAddedCB)
        this.markersArray.off('remove', this._markerRemovedCB)
      }

      this.markersArray = markersArray;
      if (this.markersArray !== undefined) {
        if (this.map !== undefined) this._createMarkers()
        this.markersArray.on('add', this._markerAddedCB)
        this.markersArray.on('remove', this._markerRemovedCB)
      }
    }
  },
  signals: {
    centerOnUser: {
      displayName: 'Center on user',
      group: 'Actions',
      signal() {
        this.geolocate && this.geolocate.trigger();
      }
    }
  },
  outputs: {
    map: {
      displayName: 'Mapbox Object',
      type: '*',
      group: 'Mapbox'
    },

    longitude: {
      displayName: 'Longitude',
      type: 'number',
      group: 'Coordinates'
    },
    latitute: {
      displayName: 'Latitude',
      type: 'number',
      group: 'Coordinates'
    },
    zoom: {
      displayName: 'Zoom',
      type: 'number',
      group: 'Coordinates'
    },
    mapLoaded: {
      displayName: 'Map Loaded',
      type: 'signal',
      group: 'Events'
    },
    mapMoved: {
      displayName: 'Map Moved',
      type: 'signal',
      group: 'Events'
    },

    // Map Clicked
    mapClicked: {
      displayName: 'Click',
      type: 'signal',
      group: 'Map Clicked'
    },
    clickedLon: {
      displayName: 'Longitude',
      type: 'number',
      group: 'Map Clicked',
      editorName: 'Clicked Longitude'
    },
    clickedLat: {
      displayName: 'Latitude',
      type: 'number',
      group: 'Map Clicked',
      editorName: 'Clicked Latitude'
    },

    // Marker Clicked
    markerClicked: {
      displayName: 'Marker Click',
      type: 'signal',
      group: 'Marker Clicked'
    },
    clickedMarkerId: {
      displayName: 'Marker Id',
      type: 'stirng',
      group: 'Marker Clicked'
    },
  },
  setup: function (context, graphModel) {
    if (!context.editorConnection || !context.editorConnection.isRunningLocally()) {
      return;
    }

    graphModel.on("nodeAdded.Mapbox Map", function (node) {

      function updatePorts() {
        var ports = []

        if (node.parameters['markerType'] === 'image') {
          ports.push({
            name: 'markerImageSource',
            group: 'Markers',
            plug: 'input',
            type: 'image',
            displayName: 'Source'
          })

          ports.push({
            name: 'markerImageWidth',
            group: 'Markers',
            plug: 'input',
            type: {
              name: 'number',
              units: ["px"]
            },
            displayName: 'Width',
            default: {
              value: 20,
              unit: 'px'
            }
          })

          ports.push({
            name: 'markerImageHeight',
            group: 'Markers',
            plug: 'input',
            type: {
              name: 'number',
              units: ["px"]
            },
            displayName: 'Height',
            default: {
              value: 20,
              unit: 'px'
            }
          })

          const _anchorEnums = [{
              label: 'Center',
              value: 'center'
            },
            {
              label: 'Top',
              value: 'top'
            },
            {
              label: 'Bottom',
              value: 'bottom'
            },
            {
              label: 'Left',
              value: 'left'
            },
            {
              label: 'Right',
              value: 'right'
            },
            {
              label: 'Top Left',
              value: 'top-left'
            },
            {
              label: 'Top Right',
              value: 'top-right'
            },
            {
              label: 'Bottom Left',
              value: 'bottom-left'
            },
            {
              label: 'Bottom Right',
              value: 'bottom-right'
            }
          ]
          ports.push({
            name: 'markerImageAnchor',
            group: 'Markers',
            plug: 'input',
            type: {
              name: 'enum',
              enums: _anchorEnums
            },
            displayName: 'Anchor',
            default: 'center'
          })
        }

        context.editorConnection.sendDynamicPorts(node.id, ports);
      }

      updatePorts()
      node.on("parameterUpdated", function (event) {
        if (event.name === "markerType") updatePorts();
      })

    })
  }
})


Noodl.defineModule({
  reactNodes: [
    MapboxNode
  ],
  nodes: [],
  settings: [{
    name: 'mapboxAccessToken',
    type: 'string',
    displayName: 'Mapbox Access Token',
    plug: 'input',
    group: 'Mapbox'
  }],
  setup() {

  }
});